"use strict";

// Content-Security-Policy does not allow inline JavaScript, so we have to set the event handlers from inside the script instead.
window.onload = onPageLoaded;

// Handling the media change to 'print' is the only way to adjust layout when printing.
window.matchMedia('print').addEventListener("change", onPageLoaded);

function onPageLoaded()
{
    connectResizeObservers();
    
    // show the masthead now the resizing has finished
    var elMasthead = getSingleElement("#masthead");
    if (elMasthead)
    {
        // Use CSS
        elMasthead.style.setProperty("opacity", "1.0");
    }
}

function resizeAllMastHeadText()
{
    resizeTitleText();
    resizeLargeSubTitleText();
    resizeCompactSubTitleText();
    resizeContactDetailsText();
}

function resizeTitleText()
{
    sizeTextToFitElement("#masthead-title", getElementDimensions("#masthead-title-container"), 8, 400);
}

function resizeLargeSubTitleText()
{
    sizeTextToFitElement("#masthead-large-layout-subtitle", getElementDimensions("#masthead-large-layout-subtitle-container"), 8, 400);   
}

function resizeCompactSubTitleText()
{
    var dimCompactSubTitle1 = getElementDimensions("#masthead-compact-layout-subtitle-1-container");
    var dimCompactSubTitle2 = getElementDimensions("#masthead-compact-layout-subtitle-2-container");

    var elCompactSubTitle1 = getSingleElement("#masthead-compact-layout-subtitle-1");
    var elCompactSubTitle2 = getSingleElement("#masthead-compact-layout-subtitle-2");

    var dimCompactSubTitle1 = getTextSizeToFitElement(elCompactSubTitle1, dimCompactSubTitle1, 8, 400);
    var dimCompactSubTitle2 = getTextSizeToFitElement(elCompactSubTitle2, dimCompactSubTitle2, 8, 400);

    var fontSizeCompactSubTitle = Math.min(dimCompactSubTitle1.fontSize, dimCompactSubTitle2.fontSize);

    setFontSizeForElement(elCompactSubTitle1, fontSizeCompactSubTitle);
    setFontSizeForElement(elCompactSubTitle2, fontSizeCompactSubTitle);
}

function resizeContactDetailsText()
{
    // var dimContactDetailsSection = getElementDimensions("#masthead-contact-details");
    // var dimContactDetailContainer = new Dimensions(dimContactDetailsSection.width, dimContactDetailsSection.height / 3.0);

    var dimContactDetailsPhoneContainer = getElementDimensions("#masthead-phone-container");
    var dimContactDetailsEmailContainer = getElementDimensions("#masthead-email-container");

    var elPhone = getSingleElement("#masthead-phone");
    var elEmail = getSingleElement("#masthead-email");

    var dimContactDetailsPhone = getTextSizeToFitElement(elPhone, dimContactDetailsPhoneContainer, 7, 40);
    var dimContactDetailsEmail = getTextSizeToFitElement(elEmail, dimContactDetailsEmailContainer, 7, 40);

    //pick the smallest font size of the three
    var contactFontSize = Number.MAX_SAFE_INTEGER;
    contactFontSize = Math.min(contactFontSize, dimContactDetailsPhone.fontSize);
    contactFontSize = Math.min(contactFontSize, dimContactDetailsEmail.fontSize);

    var contactLineHeight = contactFontSize;

    if (elPhone)
    {
        elPhone.style.setProperty("height", contactFontSize + "px");
        elPhone.style.setProperty("font-size", contactFontSize + "px");
        elPhone.style.setProperty("line-height", contactLineHeight + "px");
    }

    if (elEmail)
    {
        elEmail.style.setProperty("height", contactFontSize + "px");
        elEmail.style.setProperty("font-size", contactFontSize + "px");
        elEmail.style.setProperty("line-height", contactLineHeight + "px");
    }
}

const resizeObserverForMasthead = new ResizeObserver((entries) => 
     {
         resizeAllMastHeadText();
     });

function connectResizeObservers()
{
    var elMasthead = document.querySelector("#masthead");
    if  (elMasthead)
    {
        resizeObserverForMasthead.observe(elMasthead);
    }
}

class Dimensions
{
    constructor(width, height)
    {
        this.width = width;
        this.height = height;
    }
}

class FontDimensions extends Dimensions
{
    constructor(fontSize, width, height)
    {
        super(width, height);
        this.fontSize = fontSize;
    }

    static createFromDimensions(fontSize, dimensions)
    {
        return new FontDimensions(fontSize, dimensions.width, dimensions.height);
    }
}

function getSingleElement(htmlId)
{   
    var result = document.querySelector(htmlId);
    return result;
}

function getElementDimensions(htmlId)
{
    var width = 0;
    var height = 0;

    var el = getSingleElement(htmlId);
   
    if (!el ||
        window.getComputedStyle(el).display == 'none')
    {
        el = null;
    }
    
    if (el)
    {
        width = el.clientWidth;
        height = el.clientHeight;
    }

    return new Dimensions(width, height);
}

function getTextSizeToFitElement(el, dimContainer, minFontSize, maxFontSize)
{
    var dim = new Dimensions(0, 0);

    if (el &&
        window.getComputedStyle(el).display != 'none')
    {
        dim = GetTextSizeAndDimensionsForWidthAndHeight(el, el.textContent, dimContainer.width, dimContainer.height, minFontSize, maxFontSize);
    }

    return dim;
}

function setFontSizeForElement(el, fontSize)
{
    if (el)
    {
        el.style.fontSize = fontSize + "px";
    }
}

function sizeTextToFitElement(htmlId, dimContainer, minFontSize, maxFontSize)
{
    var el = getSingleElement(htmlId);
    var dim = getTextSizeToFitElement(el, dimContainer, minFontSize, maxFontSize);
    setFontSizeForElement(el, dim.fontSize);
}

function getCssStyle(element, prop)
{
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function copyElementCssStyle(elDst, elSrc)
{
    // Type: CSSStyleDeclaration
    const styles = window.getComputedStyle(elSrc);    

    Object.values(styles).reduce(
        (css, propertyName) => { 
            elDst.style.setProperty(propertyName, styles.getPropertyValue(propertyName));
        });
}

function GetTextDimensions(context, text, font, fontSize = null)
{
    if (fontSize)
    {
        font = font.replace("?", fontSize);
    }

    context.font = font;

    var metrics = context.measureText(text);
    let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    //let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    return new Dimensions(metrics.width, fontHeight);
}

function getCanvasFontNoSize(el = document.body)
{
  const fontWeight = window.getComputedStyle(el, null).getPropertyValue("font-weight");
  const fontFamily = window.getComputedStyle(el, null).getPropertyValue("font-family");
  
  return `${fontWeight} ?px ${fontFamily}`;
}

function GetTextSizeAndDimensionsForWidthAndHeight(el, text, width, height, minFontPx, maxFontPx)
{
    ///*DEBUG*/console.log(`GetTextSizeAndDimensionsForWidthAndHeight called: ("${text}",${width},${height},${minFontPx}, ${maxFontPx})`);

    width -= 1.0;
    height -= 1.0;

    var elCanvas = document.createElement("canvas");
    
    // Not really needed, as the canvas is a bitmap and so element level styles don't really affect it.
    //copyElementCssStyle(elCanvas, el);
    
    var font = getCanvasFontNoSize(el);
    var context = elCanvas.getContext("2d");
    context.letterSpacing = window.getComputedStyle(el, null).getPropertyValue("letter-spacing");

    if (!width || 
        !height ||
        text.length == 0)
    {
        return FontDimensions.createFromDimensions(maxFontPx, GetTextDimensions(context, text, font, maxFontPx));
    }

    minFontPx = Math.round(minFontPx);
    maxFontPx = Math.round(maxFontPx);
    
    var solutionFontPx = Math.round(minFontPx);
    
    var iteration = 0;
    var dim;

    while (minFontPx < maxFontPx)
           //&& iteration < 200)
    {   
        iteration += 1;

        var testFontPx = Math.round((minFontPx + maxFontPx) / 2);
        if (testFontPx == minFontPx ||
            testFontPx == maxFontPx)
        {
            break;
        }
        
        dim = GetTextDimensions(context, text, font, testFontPx);
        ///*DEBUG*/console.log(`Iteration ${iteration}: min=${minFontPx}, max=${maxFontPx}, test=${testFontPx} => text dim=(${dim.width}, ${dim.height})`)
        
        if (dim.width > width ||
            dim.height > height)
        {
            //too big, look for something smaller
            maxFontPx = testFontPx;
        }
        else
        {
            //fits, but maybe we could find something bigger?
            minFontPx = testFontPx;
            solutionFontPx = testFontPx;
        }
    }

    dim = GetTextDimensions(context, text, font, solutionFontPx);
    ///*DEBUG*/console.log(`Solution font size: ${solutionFontPx} (${dim.width}, ${dim.height})`)
    return FontDimensions.createFromDimensions(solutionFontPx, dim);
}